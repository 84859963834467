.dashCon {
  /* width: 100%; */
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.customDropdownStyle {
  margin-left: 5%;
  margin-bottom: 1.2%;
}
.headerBanner {
  margin-left: 1%;
  margin-right: 1%;

  /* background-color:  #001d49; */
  font-family: "Montserrat", sans-serif;
  height: 12vh;
  display: flex;
  justify-content: space-between;
}
/* 
.btn-toolbar {
  text-align: center;
} */

.call-center-img-con {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-right: -3%;
}
.button {
  color: #001d49;
}

.logoutButton {
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
}

.u-margin-bottom-Xtiny {
  margin-bottom: 0.75%;
}
/* .u-margin-bottom-Xsmall {
   margin-bottom: 15%;
 } */

.welcome-user {
  font-family: "Montserrat", sans-serif;
  font-size: 2.4rem;
  margin: 30px 0;
}

.filter-text-paragraph {
  /* text-decoration: underline overline #001b49; */

  margin-top: 5%;
  /* margin-right : 5%; */
  /* display: inline-flex; */
  font-size: 2rem;
}

.logoutButton {
  height: 32px;
  width: 32px;
}

.filter-text-paragraph-2 {
  margin-top: -3%;
  font-family: "Montserrat", sans-serif;
  margin-left: 1%;
  /* display: inline-flex; */
  font-size: 2rem;
}

.testUserButton {
  color: #001d49;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  margin-top: 20%;
  font-size: 50px;
}

.loaded {
  margin-top: 20%;
  font-size: 50px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.welcomeText {
  color: white;
  margin-left: 5%;
  margin-top: 1%;
}

.custom-date-button {
  display: flex;
  justify-content: space-around;
  margin-left: 1.5%;
}

.customButton {
  color: #001d49;
  background-color: white;
  font-size: 18px;

  display: flex;
  justify-content: flex-end;

  height: 3.4rem;
  width: 5.8rem;
}

.button_cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10%;
}

button::before,
button::after {
  content: "";
  flex: 1 0 auto;
}

.justify {
  text-align: justify;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #001d49;

  border-radius: 10vh;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  background: #fff;
  color: #001d49 !important;
}

.navbar-light .navbar-nav .nav-link {
  border-radius: 2vh;
}

.field.focussed input {
  padding: 24px 16px 8px 16px;
}
.field.focussed input + label {
  top: 4px;
  opacity: 1;
  color: #28cc70;
}
.field.locked {
  pointer-events: none;
}
.field input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 450px) {
  .welcomeBanner {
    font-size: 16px;
  }

  .filter-text-paragraph {
    margin-top: 13%;
    padding: 5px;
    font-size: 20px;
  }
  .customButton {
    margin-top: 8%;

    color: #001d49;
    background-color: white;
    font-size: 18px;

    /* background-color: antiquewhite; */
    height: 3.4rem;
    width: 5.8rem;
  }

  .filtersDiv {
    /* margin-left: 60%;  */
    /* margin-bottom: 2%; */
  }

  .call-center-img-con {
    margin-right: -9%;
  }

  .welcome-user {
    margin-left: 30%;
    margin-top: -3%;
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
  }

  .btn-toolbar {
    margin-left: 28%;
  }

  .custom-date-button {
    margin-left: 3%;
    font-size: 1rem;
  }

  .customButton {
    margin-left: -2rem;
  }

  .dashCon {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}
