.SampleBox {
  display: flex;
  align-items: center;
  justify-content: center;

  align-items: center;
}

.SampleContainer {
  background-image: url("../assets/bg-3.jpg");
  display: flex;
  /* justify-content: flex-start; */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  max-width: 100%;
}

.emailHelp {
  justify-content: center;

  display: flex;
  text-align: center;
  color: #fff;
  font-size: 2.2rem;
}
/* 
.box {
  background-color: aliceblue;
  border-radius: 5vh;
  height: 40vh;
  width: 40vh;
  opacity: .7;
   
  display: flex;
  align-items: center;
  justify-content: center;

  
  align-items: center;
} */

/* .login-img-container {
    background-image: url('../assets/landing-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    top: -2px; 
  } */

.imgCon {
  padding: 0.2rem;
  margin-left: 10px;
  color: #001d49;
  /* background-color: antiquewhite; */
  background-image: url("../assets/365.png");
  display: inline;
}

.img365 {
  margin: -20%;
  width: 48px;
}

.customButton {
}

#customButt {
  font-size: 32px;
  color: #fff;
  /* background-color: antiquewhite; */
  height: 5rem;
  width: 15rem;
  background-color: rgba(0, 27, 73);
  border-radius: 2vh;
  opacity: 0.85;
}

/* @media only screen and (max-width: 450px) {
  html {
    display: none;
  }
} */
